<template>
  <DashboardAdminTemplate>
    <div
      class="pd-x-5"
      v-loading.fullscreen.lock="loadingDBD"
      element-loading-text="กำลัง Sync ข้อมูล DBD รายบุคคล"
    >
      <el-row align="middle" type="flex">
        <el-col :span="8">
          <div class="is-flex ai-center">
            <h2>จัดการข้อมูลเพื่อการวิเคราะห์</h2>
          </div>
        </el-col>
        <el-col :span="16" class="text-right is-flex js-end ai-center">
          <el-tooltip
            class="item"
            effect="dark"
            content="จัดการหน้าผู้ใช้งาน"
            placement="bottom"
          >
            <span>
              <a href="/manage/menu" class="text-primary">
                <i class="fas fa-cog font-28"></i>
              </a>
            </span>
          </el-tooltip>
          <span class="text-danger" v-if="$route.query.mail">
            <el-button
              type="primary"
              v-if="!openMenu"
              icon="el-icon-message "
              @click="sendEmail()"
              plain
              circle
            ></el-button>
            *** ปิดเพื่อส่งอีเมล (ไม่ได้ใช้แล้ว) ***</span
          >
          <el-tooltip
            class="item"
            effect="dark"
            v-if="lastSync.sync == 0"
            content="มีกำหนดการ Sync ข้อมูลกับ DBD เวลา 18.00 น."
            placement="bottom"
          >
            <span>
              <el-button
                type="primary"
                style="width: 130px"
                class="mg-x-5"
                :disabled="true"
                ><i class="far fa-clock"></i> DBD Sync</el-button
              >
            </span>
          </el-tooltip>
          <el-dropdown trigger="click" v-else>
            <el-button type="primary" style="width: 130px" class="mg-x-5"
              >DBD Sync <i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><div @click="syncDBD()">
                  Sync DBD ทั้งหมด
                </div></el-dropdown-item
              >
              <el-dropdown-item
                ><div @click="dialogSyncDBD = true">
                  เลือกช่วงวันที่ Sync DBD
                </div></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" @command="handleCommand">
            <el-button
              type="primary"
              style="width: 130px"
              v-loading.fullscreen.lock="fullscreenLoading"
            >
              Export <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">Export ทั้งหมด</el-dropdown-item>
              <el-dropdown-item command="manange"
                >จัดการตาราง Export</el-dropdown-item
              >
              <el-dropdown-item
                ><router-link to="/file-export" class="text-dark-link"
                  >รายการไฟล์ Export ทั้งหมด</router-link
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <!-- dialog -->
        <el-dialog
          class="text-center font-32"
          title="จัดการตาราง Export CSV"
          :visible.sync="dialogFormVisible"
        >
          <Export @sentExport="ExportCustom" :columnProp="darfColumn" />
        </el-dialog>
      </el-row>

      <div class="bordered rounded pd-2 pd-t-4 pd-b-5 mg-b-3">
        <el-form ref="filter" :model="filter" label-position="top">
          <el-row>
            <el-col :span="4" :offset="0">
              <el-form-item
                label="ปีที่ทำการประเมิน"
                class="mg-r-5 padding-less"
              >
                <el-select
                  class="w-100"
                  v-model="filter.year"
                  @change="handleCurrentChange(1)"
                  clearable
                  placeholder="ปีที่ทำการประเมิน"
                >
                  <el-option
                    v-for="(item, index) in getYear"
                    :key="index"
                    :label="item.label"
                    :value="item.value - 543"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item label="ขนาดของธุรกิจ" class="mg-r-5 padding-less">
                <el-select
                  class="w-100"
                  @change="handleCurrentChange(1)"
                  v-model="filter.businessSize"
                  clearable
                  placeholder="เลือกขนาดของธุรกิจ"
                >
                  <el-option label="ธุรกิจขนาดเล็ก (S)" value="S"> </el-option>
                  <el-option label="ธุรกิจขนาดกลาง (M)" value="M"> </el-option>
                  <el-option label="ธุรกิจขนาดใหญ่ (L)" value="L"> </el-option>
                  <el-option label="ทุกขนาดธุรกิจ (S, M, L)" value="SML">
                  </el-option>
                  <el-option label="ไม่มีขนาดธุรกิจ" value="N"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item label="ลักษณะธุรกิจ" class="mg-r-5 padding-less">
                <el-select
                  class="w-100"
                  @change="handleCurrentChange(1)"
                  v-model="filter.businessType"
                  clearable
                  placeholder="เลือกลักษณะธุรกิจ"
                >
                  <el-option
                    v-for="item in businessType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item
                label="ประเภทอุตสาหกรรม"
                class="mg-r-5 padding-less"
              >
                <el-select
                  class="w-100"
                  @change="handleCurrentChange(1), (filter.industryType = '')"
                  v-model="filter.categoryType"
                  clearable
                  filterable
                  placeholder="เลือกประเภทอุตสาหกรรม"
                >
                  <el-option
                    v-for="(data, index) in industryTypeOption"
                    :label="data.label"
                    :value="data.id"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item
                label="ประเภทย่อยของอุตสาหกรรม"
                class="mg-r-5 padding-less"
              >
                <el-select
                  class="w-100"
                  @change="handleCurrentChange(1)"
                  :disabled="
                    filter.categoryType == '' || filter.categoryType == 28
                  "
                  v-model="filter.industryType"
                  clearable
                  filterable
                  placeholder="เลือกประเภทย่อยของอุตสาหกรรม"
                >
                  <el-option
                    v-for="item in subIndustry"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="4" :offset="0">
              <el-form-item
                label="หน่วยงานที่เป็นสมาชิก"
                class="mg-r-5 padding-less"
              >
                <el-select
                  @change="handleCurrentChange(1)"
                  multiple
                  default-first-option
                  popper-class="custom-select"
                  v-model="filter.memberOf"
                  clearable
                  placeholder="เลือกหน่วยงาน"
                  class="w-100"
                >
                  <div class="pd-x-5">
                    <!-- <div class="font-we">หน่วยงานภายใน</div>
                    <el-option
                      v-for="item in fucFilter(memberOfOrganize, 'group', 2)"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.label }}
                      </div>
                    </el-option>
                    <el-divider class="mg-y-5"></el-divider>
                    <div>หน่วยงานภายนอก</div> -->
                    <el-option
                      v-for="item in fucFilter(memberOfOrganize, 'group', 1)"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.label }}
                      </div>
                    </el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item label="ค้นหา" class="mg-r-5 padding-less">
                <el-tooltip placement="bottom" :open-delay="800">
                  <div slot="content">
                    ค้นหาจาก ชื่อ / นามสกุล / อีเมล / <br />
                    ชื่อบริษัท / เลขนิติบุคคล / ชื่อโครงการ / แหล่งที่มา
                  </div>
                  <el-input
                    clearable
                    @input="handleCurrentChange(1, 600)"
                    class="el-input--suffix"
                    v-model="filter.search"
                    placeholder="พิมพ์คำค้นหา"
                  ></el-input>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item label="สถานะการใช้งาน" class="mg-r-5 padding-less">
                <el-select
                  class="w-100"
                  @change="handleCurrentChange(1)"
                  v-model="filter.status"
                  clearable
                  placeholder="ทั้งหมด"
                >
                  <el-option :label="'ใช้งาน'" :value="1"> </el-option>
                  <el-option :label="'ละเว้น'" :value="0"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item label="สถานะแบบประเมิน" class="mg-r-5 padding-less">
                <el-select
                  class="w-100"
                  v-model="filter.statusAssessment"
                  clearable
                  placeholder="ทั้งหมด"
                  @change="handleCurrentChange(1)"
                >
                  <el-option :label="'ประเมินตามค่า Default'" :value="0">
                  </el-option>
                  <el-option :label="'อยู่ระหว่างประเมิน'" :value="2">
                  </el-option>
                  <el-option :label="'ประเมินเรียบร้อยแล้ว'" :value="1">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item
                label="สถานะเลขนิติบุคคล"
                class="mg-r-5 padding-less"
              >
                <el-select
                  class="w-100"
                  @change="handleCurrentChange(1)"
                  v-model="filter.statusJuristic"
                  clearable
                  placeholder="ทั้งหมด"
                >
                  <el-option :label="'ตามข้อมูล DBD'" :value="1"> </el-option>
                  <el-option :label="'ไม่มีข้อมูลตาม DBD'" :value="0">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item
                label="สถานะข้อมูลส่วนบุคคล"
                class="mg-r-5 padding-less"
              >
                <el-select
                  class="w-100"
                  v-model="filter.consent"
                  @change="handleCurrentChange(1)"
                  clearable
                  placeholder="ทั้งหมด"
                  multiple
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option
                    v-for="item in infoOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-form-item label="สถานะกรรมการ" class="mg-r-5 padding-less">
                <el-select
                  class="w-100"
                  @change="handleCurrentChange(1)"
                  clearable
                  placeholder="ทั้งหมด"
                  multiple
                  v-model="filter.typeCommittee"
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option label="ไม่ได้เป็นกรรมการ" value="N">
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      ไม่ได้เป็นกรรมการ
                    </div>
                  </el-option>
                  <el-option label="K: กรรมการหุ้นส่วน" value="K">
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      K: กรรมการหุ้นส่วน
                    </div>
                  </el-option>
                  <el-option label="L: หุ้นส่วนผู้จัดการ" value="L">
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      L: หุ้นส่วนผู้จัดการ
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4" :offset="0">
              <el-form-item
                label="สถานะประเมินความพึงพอใจ"
                class="mg-r-5 padding-less"
              >
              
                <el-switch
                  @change="handleCurrentChange(1)"
                  v-model="filter.statusComplacency"
                  :active-value="true"
                  inactive-text=""
                  :active-text="
                    filter.statusComplacency ? 'ประเมินเรียบร้อย' : 'ทั้งหมด'
                  "
                >
                </el-switch>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
        <div style="overflow: hidden"></div>
      </div>
      <div v-loading="loading">
        <div class="text-right mg-b-5 text-grey-light font-16" v-if="lastSync">
          ข้อมูลจาก DBD อัปเดตล่าสุดเมื่อ
          {{ lastSync.updatedAt | dateTimeTh }}
        </div>
        <div>
          <el-table border :data="userList" style="width: calc(100% - 5px)">
            <template slot="empty">
              <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
            </template>

            <el-table-column
              prop="name"
              label="ชื่อ"
              align="center"
              header-align="center"
              width="81px"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.type_committee"
                  placement="bottom-start"
                  v-if="scope.row.type_committee"
                >
                  <span class="text-primary">
                    <i class="fas fa-user-tie"></i>
                  </span>
                </el-tooltip>
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column
              prop="surname"
              label="นามสกุล"
              align="center"
              header-align="center"
              width="80px"
            >
            </el-table-column>
            <el-table-column
              prop="email"
              label="อีเมล"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="ชื่อบริษัท"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="corporateNumber"
              label="เลขนิติบุคคล"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div
                  class="is-flex flex-warp ai-center js-center"
                  :class="{
                    'text-danger':
                      !scope.row.dbd ||
                      scope.row.corporateNumber == '0000000000000',
                  }"
                >
                  {{ scope.row.corporateNumber }}
                  <i
                    v-if="
                      !scope.row.dbd ||
                        scope.row.corporateNumber == '0000000000000'
                    "
                    class="el-icon-warning mg-l-7"
                  ></i>
                  <el-tooltip
                    class="item"
                    v-if="scope.row.lastSyncDBD"
                    effect="dark"
                    :content="moment(scope.row.lastSyncDBD).format('lll น.')"
                    placement="top"
                  >
                    <span>
                      <i
                        class="fas fa-sync-alt cr-pointer mg-l-7"
                        style="font-size: 13px"
                        @click="sysDbdByUserId(scope.row.id)"
                      ></i>
                    </span>
                  </el-tooltip>
                  <span v-else>
                    <i
                      class="fas fa-sync-alt cr-pointer mg-l-7"
                      style="font-size: 13px"
                      @click="sysDbdByUserId(scope.row.id)"
                    ></i>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="year"
              label="ปี"
              align="center"
              header-align="center"
              width="60px"
            >
              <template slot-scope="scope">
                {{ Number(scope.row.year) + 543 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="businessSize"
              label="ขนาดธุรกิจ"
              align="center"
              width="60px"
              header-align="center"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.businessSize == 'S'
                      ? 'S'
                      : scope.row.businessSize == 'M'
                      ? 'M'
                      : scope.row.businessSize == 'L'
                      ? 'L'
                      : '-'
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="businessType"
              label="ลักษณะธุรกิจ"
              align="center"
              header-align="center"
              width="66px"
            >
              <template slot-scope="scope">
                {{ getLabelBusinessType(scope.row.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="industryType"
              label="ประเภทอุตสาหกรรม"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.industryType != 0">
                  {{
                    getLabelIndustryType(
                      scope.row.cateIndustryType,
                      scope.row.industryType
                    )
                  }}
                </div>
                <div v-else>
                  {{ scope.row.industryTypeEtc }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="memberOf"
              label="หน่วยงานที่เป็นสมาชิก"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div>
                  <span
                    v-for="(data, index) in scope.row.memberOf"
                    :key="index"
                  >
                    {{
                      data.memberOf == 7
                        ? 'สมาชิก EXAC'
                        : data.memberOf != 6
                        ? getNameMember(data.memberOf)
                        : data.memberOfEtc
                    }}<span v-if="index + 1 < scope.row.memberOf.length"
                      >,
                    </span>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="โครงการ"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="ref"
              label="แหล่งที่มา"
              align="center"
              header-align="center"
              width="90px"
            >
            </el-table-column>
            <el-table-column
              prop="createdAt"
              label="สมัครเมื่อ"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.createdAt != 6">
                  {{ moment(scope.row.createdAt).format('lll น.') }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="สถานะ"
              align="right"
              header-align="center"
              width="125px"
            >
              <template slot-scope="scope">
                <el-tooltip placement="bottom" :open-delay="800">
                  <div slot="content">{{ scope.row.transactionType }}</div>
                  <span>
                    <i
                      class="fas fa-user-slash text-danger mg-r-7"
                      v-if="
                        scope.row.transactionTypeId == 1 ||
                          scope.row.transactionTypeId == 3
                      "
                    ></i>
                  </span>
                </el-tooltip>
                <el-tooltip
                  :content="
                    scope.row.recommendStatus == 1
                      ? 'ประเมินเรียบร้อยแล้ว'
                      : scope.row.descriptionRecommendStatus == 'กำลังประเมิน'
                      ? 'อยู่ระหว่างประเมิน'
                      : ''
                  "
                  :open-delay="500"
                  placement="bottom"
                >
                  <span
                    class="mg-r-7"
                    v-if="scope.row.recommendStatus == 1"
                    title="ประเมินเรียบร้อยแล้ว"
                  >
                    <i class="el-icon-document-checked text-primary"></i>
                  </span>
                  <span
                    class="mg-r-7"
                    v-if="
                      scope.row.descriptionRecommendStatus == 'กำลังประเมิน'
                    "
                    title="อยู่ระหว่างประเมิน"
                  >
                    <i class="el-icon-document text-warning"></i>
                  </span>
                </el-tooltip>
                <el-dropdown trigger="click" class="mg-r-6">
                  <span class="el-dropdown-link">
                    <span
                      :class="
                        scope.row.status == 0
                          ? 'color-grey-light'
                          : 'color-success'
                      "
                      >{{ getLabelStatusUser(scope.row.status) }}</span
                    >
                    <i
                      class="
                        fas
                        fa-ellipsis-v
                        mg-l-6
                        color-grey
                        cr-pointer
                        hover-primary
                      "
                    ></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="scope.row.recommendStatus != 2"
                      ><div
                        @click="
                          exportReport(
                            scope.row.recommendStatus == 0
                              ? scope.row.id
                              : scope.row.companyId,
                            scope.row.recommendStatus
                          )
                        "
                      >
                        Export Report
                      </div></el-dropdown-item
                    >
                    <el-dropdown-item
                      v-for="item in statusList"
                      :key="item.value"
                      ><div
                        @click="
                          updateStatusUser(scope.row.companyId, item.value)
                        "
                      >
                        {{ item.label }}
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item divided class="color-danger"
                      ><a
                        :href="
                          `/edit-user-profile/${scope.row.id}/${scope.row.companyId}`
                        "
                        class="text-grey"
                        target="_blank"
                      >
                        ข้อมูลส่วนตัว
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item class="color-danger"
                      ><a
                        :href="
                          `/edit-company-profile/${scope.row.id}/${scope.row.companyId}`
                        "
                        class="text-grey"
                        target="_blank"
                      >
                        ข้อมูลบริษัท
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item class="color-danger"
                      ><a
                        :href="
                          `/statement/${scope.row.id}/${scope.row.companyId}`
                        "
                        class="text-grey"
                        target="_blank"
                      >
                        ข้อมูลงบการเงิน
                      </a></el-dropdown-item
                    >
                    <el-dropdown-item divided class="color-danger"
                      ><div
                        @click="
                          delUser(scope.row.id, scope.row.name, scope.row.email)
                        "
                      >
                        ลบข้อมูลผู้ใช้
                      </div></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="mg-y-3">
        <div class="block text-center">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[15, 30, 50, 100, 500]"
            :page-size="15"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalUser"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogSyncDBD"
      :destroy-on-close="true"
      @close="timeDBD.date = []"
      width="50%"
    >
      <h1 class="text-center mg-t-0">เลือกช่วงวันที่ในการ Sync ข้อมูล DBD</h1>
      <el-form :model="timeDBD" ref="formSyncDBD" label-position="top">
        <el-form-item
          label="กรุณาเลือกช่วงวันที่ในการ Sync ข้อมูล DBD"
          prop="date"
          class="sync--time"
          :rules="[
            {
              required: true,
              message: 'กรุณาเลือกช่วงวันที่ในการ Sync ข้อมูล DBD',
            },
          ]"
        >
          <el-date-picker
            v-model="timeDBD.date"
            type="datetimerange"
            range-separator="ถึง"
            start-placeholder="วันที่เริ่มต้น"
            end-placeholder="วันที่สิ้นสุด"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="text-center">
        <el-button type="primary" @click="funcSyncTimeDBD()">Sync</el-button>
      </div>
    </el-dialog>
  </DashboardAdminTemplate>
</template>

<script>
  import exportModel from '@/model/export'
  import {HTTP} from '@/service/axios'
  import userService from '@/service/userListService'
  import DashboardAdminTemplate from '@/template/DashboardAdminTemplate'
  import Export from '@/views/Export.vue'
  import moment from 'moment'
  export default {
    components: {
      DashboardAdminTemplate,
      Export,
    },
    computed: {
      infoOption() {
        return this.$store.state.infoOption
      },
      subIndustry() {
        if (typeof this.filter != 'undefined') {
          let filterSub = this.$store.state.industryType.filter(
            (a) =>
              this.filter.categoryType != '' &&
              a.id === this.filter.categoryType
          )
          if (filterSub.length > 0) {
            return filterSub[0].subIndustry
          } else {
            return []
          }
        } else {
          return []
        }
      },
      user() {
        return this.$store.state.user
      },
      token() {
        return this.$store.state.token
      },
      businessType() {
        return this.$store.state.businessType
      },
      industryTypeOption() {
        let fitler = this.$store.state.industryType
        //11/8/2021 ปิด filter 26 / 27 ให้ออกมาโชเหมือนเดิม
        //fitler = fitler.filter(a => a.id != 26 && a.id != 27);
        return fitler
      },
      memberOfOrganize() {
        return this.$store.state.memberOfOrganize
      },
      statusList() {
        return this.$store.state.statusList
      },
      openMenuStore() {
        return this.$store.state.openMenu
      },
    },
    data() {
      return {
        isExport: false,
        timeDBD: {
          date: [],
        },
        dialogSyncDBD: false,
        timeDiff: null,
        value: '',
        totalUser: 0,
        getYear: [],
        darfColumn: null,
        dialogFormVisible: false,
        now: new Date(),
        currentPage: 1,
        fullscreenLoading: false,
        loading: true,
        isLoading: false,
        userListConst: [],
        userList: [],
        debounce: null,
        filter: {
          year: '',
          consent: [],
          businessSize: '',
          businessType: '',
          industryType: '',
          categoryType: '',
          typeCommittee: [],
          memberOf: [],
          search: '',
          status: '',
          statusAssessment: '',
          statusJuristic: '',
          statusComplacency: false,
          limit: 15,
          offset: 0,
        },
        filtersInput: [
          {
            value: '',
          },
          {
            value: [],
          },
        ],
        tableProps: {
          border: true,
          stripe: true,
        },
        openMenu: true,
        pagiUser: [],
        lastSync: {id: 0},
        loadingDBD: false,
      }
    },
    mounted() {
      this.fetchUserList()
      this.fetchStatusMenu()
      this.getLastSync()
      this.getYearFilter()
      this.getCheckExport()
      window.setInterval(() => {
        this.now = Math.trunc(new Date())
      }, 1000)
    },
    methods: {
      async getCheckExport() {
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
        let res = await HTTP.get(`logs/check`)
        if (res.data.success) {
          res.data.obj.length > 0
            ? (this.isExport = true)
            : (this.isExport = false)
        }
      },
      async funcSyncTimeDBD() {
        this.$refs['formSyncDBD'].validate(async (valid) => {
          if (valid) {
            let obj = {
              startDate: `${this.timeDBD.date[0].getFullYear()}-${this.timeDBD.date[0].getMonth() +
                1}-${this.timeDBD.date[0].getDate()} 00:00:00`,
              endDate: `${this.timeDBD.date[1].getFullYear()}-${this.timeDBD.date[1].getMonth() +
                1}-${this.timeDBD.date[1].getDate()} 23:59:59`,
            }
            HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
            let res = await HTTP.post(`update/date/sync/dbd`, obj).catch(
              (e) => {
                if (e == 'Error: Request failed with status code 401') {
                  this.checkAuth()
                } else {
                  if (e != 'Error: timeout of 120000ms exceeded') {
                    this.alertCatchError(e)
                  }
                }
                return {
                  data: {
                    data: e,
                    success: false,
                  },
                }
              }
            )
            if (res.data.success) {
              this.$message({
                message: 'ข้อมูล DBD จะถูกอัปเดตหลังเวลา 18.00 น.',
                type: 'success',
              })
            }
            this.handleCurrentChange(this.currentPage)
            setTimeout(() => {
              this.getLastSync()
            }, 800)
            this.dialogSyncDBD = false
          }
        })
      },
      sysDbdByUserId(id) {
        this.loadingDBD = true
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
        HTTP.post(`system/admin/request/dbd/juristicId`, {userId: id})
          .then((result) => {
            if (result.data.success) {
              if (result.data.dbd) {
                this.$message({
                  message: 'Sync ข้อมูลสำเร็จ',
                  type: 'success',
                })
              } else {
                this.$message.error({
                  message: 'เลขนิติบุคคลนี้ไม่มีข้อมูลตาม DBD',
                })
              }
              this.handleCurrentChange(this.currentPage)
            } else {
              this.loadingDBD = false
            }
          })
          .catch((e) => {
            this.loadingDBD = false
            if (e == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              if (e != 'Error: timeout of 120000ms exceeded') {
                this.alertCatchError(e)
              }
            }
          })
      },
      // timeSync() {
      //   let days = 0;
      //   console.log("this.lastSync", this.lastSync);
      //   let now = moment();
      //   let end = moment(this.lastSync.updatedAt).add(1, "days");
      //   let next = moment().add(1, "days");

      //   end.set({ hour: 3, minute: 45, second: 0, millisecond: 0 });
      //   end.toISOString();
      //   end.format();
      //   let duration = moment.duration(now.diff(end));
      //   days = duration.asMinutes();
      //   console.log("now", now);
      //   console.log("end", end);
      //   console.log("next", next);
      //   console.log("days", days);
      //   if (days > 0) {
      //     this.timeDiff = true;
      //   } else {
      //     this.timeDiff = false;
      //   }
      // },
      syncDBD() {
        HTTP.get(`/12QivZmuHdyulOlSH9Re`).finally(() => {
          this.$message({
            type: 'success',
            message: 'ข้อมูล DBD จะถูกอัปเดตหลังเวลา 18.00 น.',
          })
        })
        setTimeout(() => {
          this.getLastSync()
        }, 800)
      },
      getLastSync() {
        HTTP.get(`/last/sync`)
          .then((res) => {
            if (res.data.success) {
              this.lastSync = res.data.obj
            }
          })
          .catch((e) => {
            if (e == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              if (e != 'Error: timeout of 120000ms exceeded') {
                this.alertCatchError(e)
              }
            }
          })
      },
      fucFilter(data, param1 = 'value', param2) {
        let obj = data.filter((a) => a[param1] == param2)
        if (obj.length > 0) {
          return obj
        } else {
          return ''
        }
      },
      exportAll() {
        let customfield = exportModel
        Object.keys(customfield).forEach((item) => {
          customfield[item] = true
        })
        this.ExportCustom(customfield)
      },
      ExportCustom(val) {
        this.fullscreenLoading = true
        this.dialogFormVisible = false
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
        HTTP.defaults.timeout = 10000 * 60
        HTTP.post(`export/custom`, val)
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              // var name = "_blank";
              // var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];

              // const url =
              //   process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.filename;
              // //const win = window.open(url, specs);
              // var createA = document.createElement("a");
              // createA.setAttribute("href", url);
              // createA.setAttribute("target", "_blank");
              // setTimeout(() => {
              //   createA.click();
              // }, 400);
              this.$message({
                type: 'success',
                message: 'ส่งออกข้อมูลสำเร็จ',
              })
              this.$router.push('/file-export')
              this.fullscreenLoading = false
            }
          })
          .finally(() => {
            this.fullscreenLoading = false
          })
          .catch((e) => {
            console.log(e)
          })
      },
      getDraft() {
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
        HTTP.get(`draft`)
          .then((res) => {
            if (res.data.success) {
              if (res.data.obj.length > 0) {
                Object.keys(res.data.obj[0]).forEach((item) => {
                  if (item != 'createdAt' || item != 'id') {
                    res.data.obj[0][item] == 0
                      ? (res.data.obj[0][item] = false)
                      : (res.data.obj[0][item] = true)
                  }
                })
                this.darfColumn = res.data.obj[0]
              } else {
                this.darfColumn = exportModel
              }
            }
          })
          .catch((e) => {
            if (e == 'Error: Request failed with status code 401') {
              this.checkAuth()
            }
          })
          .finally(() => {
            this.dialogFormVisible = true
          })
      },
      handleCommand(command) {
        command == 'all' ? this.exportDataCSV() : this.getDraft()
      },
      handleSizeChange(val) {
        this.filter.limit = val
        this.handleCurrentChange(1)
      },
      handleCurrentChange(val, time = 0) {
        this.isLoading = true
        this.currentPage = val
        let end = this.currentPage * this.filter.limit
        let start = (this.currentPage - 1) * this.filter.limit
        this.filter.offset = start
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.fetchUserList()
        }, time)
      },
      exportReport(id, status) {
        var name = '_blank'
        var specs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes']
        const url = '/export/result/' + id + '/' + status
        //const win = window.open(url, specs);
        var createA = document.createElement('a')
        createA.setAttribute('href', url)
        createA.setAttribute('target', '_blank')
        setTimeout(() => {
          createA.click()
        }, 400)
      },
      getNameMember(number = '') {
        if (number != '') {
          let data = this.memberOfOrganize.filter((a) => a.value === number)
          if (data.length > 0) {
            return data[0].shortName
          } else {
            return '-'
          }
        } else {
          return '-'
        }
      },
      exportDataCSV() {
        if (this.isExport) {
          this.$message({
            type: 'warning',
            message: 'คุณได้ทำการ Export ไฟล์ของวันนี้แล้ว',
          })
          return
        }
        this.fullscreenLoading = true
        HTTP.defaults.timeout = 10000 * 60
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.get(`export/companies`)
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              var name = '_blank'
              var specs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes']

              const url =
                process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.filename
              //const win = window.open(url, specs);
              // var createA = document.createElement("a");
              // createA.setAttribute("href", url);
              // createA.setAttribute("target", "_blank");
              this.$router.push('/file-export')
              setTimeout(() => {
                createA.click()
              }, 400)

              // this.$message({
              //   type: "success",
              //   message: "ส่งออกข้อมูลสำเร็จ",
              // });
              this.fullscreenLoading = false
            }
          })
          .catch((e) => {
            if (e == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              if (e != 'Error: timeout of 120000ms exceeded') {
                this.alertCatchError(e)
              }
            }
          })
      },
      sendEmail() {
        this.$confirm('ยืนยันการส่งอีเมล', {
          confirmButtonText: 'ตกลง',
          cancelButtonText: 'ยกเลิก',
          type: 'warning',
        })
          .then(() => {
            HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
            HTTP.get(`email/open/function`).then((res) => {
              if (res.data.success) {
                this.$message({
                  type: 'success',
                  message: 'ส่งอีเมลสำเร็จ',
                })
              }
            })
          })
          .catch(() => {})
      },
      async fetchStatusMenu() {
        let obj = {
          token: this.token,
        }
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`
        await HTTP.get(`close/function`)
          .then((res) => {
            if (res.data.success) {
              let status = res.data.obj.status == 1 ? true : false
              this.$store.commit('SET_OPEN_MENU', status)
            }
          })
          .catch((e) => {
            if (e == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              if (e != 'Error: timeout of 120000ms exceeded') {
                this.alertCatchError(e)
              }
            }
            console.log('FETCH_OPEN_MENU error', e.response)
          })
        this.openMenu = this.openMenuStore
      },
      async getYearFilter() {
        try {
          let res = await userService.getYear(this.token)
          if (res.data.success) {
            res.data.obj.map((f) => {
              this.getYear.push({label: f, value: f})
            })
          } else {
            if (res.data.data == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              this.alertCatchError(res.data.data)
            }
          }
        } catch {
          console.log('getYearFilter ERROR')
        }
      },
      async fetchUserList() {
        this.loading = true
        try {
          let obj = {
            ...this.filter,
            statusComplacency:
              this.filter.statusComplacency == false
                ? ''
                : this.filter.statusComplacency,
          }
          let res = await userService.getUserList(this.token, obj)
          if (res.data.success) {
            this.userList = res.data.obj
            this.totalUser = res.data.total
          } else {
            if (res.data.data == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              console.log('fetchUserList API Error')
            }
          }
          this.loadingDBD = false
          this.loading = false
        } catch {
          this.loadingDBD = false
          this.loading = false
          console.log(`fetchUserList ERROR`)
        }
      },
      updateStatusUser(companyId, status) {
        this.loading = true
        let obj = {
          companyId: companyId,
          status: status,
        }
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
        HTTP.put(`status/company`, obj)
          .then((res) => {
            if (res.data.success) {
              this.fetchUserList()
            } else {
              this.alertFailError()
              this.loading = false
            }
          })
          .catch((e) => {
            if (e == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              if (e != 'Error: timeout of 120000ms exceeded') {
                this.alertCatchError(e)
              }
            }
            this.loading = false
            console.log('updateStatusUser error', e)
          })
      },
      delUser(id, name, email) {
        // this.loading = true;
        this.$confirm(
          'หากยืนยันข้อมูลของ ' +
            name +
            ' (' +
            email +
            ')' +
            ' จะลบออกจากระบบถาวร',
          'ยืนยันการลบข้อมูล',
          {
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            type: 'warning',
          }
        )
          .then(() => {
            this.loading = true
            HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
            HTTP.delete(`user/${id}`)
              .then((res) => {
                if (res.data.success) {
                  this.fetchUserList()
                } else {
                  this.alertFailError()
                  this.loading = false
                }
              })
              .catch((e) => {
                if (e == 'Error: Request failed with status code 401') {
                  this.checkAuth()
                } else {
                  if (e != 'Error: timeout of 120000ms exceeded') {
                    this.alertCatchError(e)
                  }
                }
                this.loading = false
                console.log('delUser error', e)
              })
            this.$message({
              type: 'success',
              message: 'ลบข้อมูลสำเร็จ',
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'ยกเลิกการลบ',
            })
            this.loading = false
          })
      },
      getLabelBusinessType(id) {
        let filter = this.businessType.filter((filter) => filter.value == id)
        if (filter.length > 0) {
          return filter[0].shortName
        } else {
          return ''
        }
      },
      getLabelIndustryType(cateId, id) {
        let filter = []
        //โชว์ประเภทหลัก
        // this.$store.state.industryType.map(b => {
        //   if (b.id == cateId) {
        //     filter = b.subIndustry.filter(a => a.value == id);
        //   }
        // });
        filter = this.$store.state.industryType.filter((a) => a.id == cateId)
        if (filter.length > 0) {
          return filter[0].label
        }
        return '-'
      },
      getLabelMemberOfOrganize(id) {
        let filter = this.memberOfOrganize.filter(
          (filter) => filter.value == id
        )
        if (filter.length > 0) {
          return filter[0].label
        } else {
          return '-'
        }
      },
      getLabelStatusUser(id) {
        let filter = this.statusList.filter((filter) => filter.value == id)
        if (filter.length > 0) {
          return filter[0].label
        } else {
          return '-'
        }
      },

      updateopenMenu() {
        let obj = {
          status: this.openMenu ? 1 : 0,
        }
        // console.log(obj);
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
        HTTP.put(`close/function`, obj)
          .then((res) => {
            // console.log("updateopenMenu", res);
            if (res.data.success) {
            } else {
              this.alertFailError()
              this.loading = false
            }
          })
          .catch((e) => {
            if (e == 'Error: Request failed with status code 401') {
              this.checkAuth()
            } else {
              if (e != 'Error: timeout of 120000ms exceeded') {
                this.alertCatchError(e)
              }
            }
            console.log('updateopenMenu error', e)
          })
      },
    },
  }
</script>
