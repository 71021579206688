const exportModel = {
  statusDataCompany: false,
  used: false,
  notUsed: false,

  statusAssessment: false,
  notAssessment: false,
  notFinished: false,
  finish: false,

  userInformation: false,
  createdAt: false,
  titleName: false,
  name: false,
  surname: false,
  consent: false,
  gender: false,
  age: false,
  education: false,
  mobilephone: false,
  email: false,
  ref: false,

  companyInformation: false,
  companyName: false,
  registerDate: false,
  registerCapital:false,
  juristicId: false,
  position: false,
  tsic: false,
  directorType: false,
  emailCompany: false,
  businessType: false,
  industryType: false,
  cateIndustryType: false,
  memberof: false,
  employment: false,
  latestAnnualRevenues: false,
  operatorType: false,
  exportPlan: false,
  exportExperience: false,
  exportRatio: false,
  orderConsistency: false,
  exportFrequency: false,
  exportSales: false,
  exportCountry: false,
  moreExportExpectations: false,
  shipment: false,
  paymentTerm: false,

  dbdInformation: false,
  // year: false,
  totalCurrentAsset: false,
  currentLiabilities: false,
  accountReceivable: false,
  shareholderEquity: false,
  inventory: false,
  liabilitiesShareholderEquity: false,
  totalAsset: false,
  totalLiabilities: false,
  totalLiabilitiesShareholderEquity: false,
  totalShare: false,
  adminExpenses: false,
  costOfGoodsSold: false,
  earningPerShare: false,
  incomeTax: false,
  interestExpenses: false,
  netProfit: false,
  saleRevenue: false,
  totalRevenue: false,
  netProfitBeforeTax:false,

  cate: false,
  businessManagementStrategy: false,
  productionAndDistribution: false,
  productAspectAndProductDevelopment: false,
  marketing: false,
  financialPlansAndBusinessRiskManagement: false,

  motivation: false,
  businessProcess: false,
  strategy: false,
  operatingPlan: false,
  strategicPartner: false,

  capacity: false,
  sourcing: false,
  facInventory: false,
  logistics: false,

  productDifferentiation: false,
  barriersToCopy: false,
  customization: false,
  standard: false,
  productDevelopment: false,

  countryMarketExploration: false,
  prospectExploration: false,
  channelExploration: false,
  segmentation: false,
  positioning: false,
  branding: false,
  pricing: false,

  costStructure: false,
  financialPlaning: false,
  riskMitigation: false,
};

export default exportModel;