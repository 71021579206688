<template>
  <div id="export">
    <div class="mg-t-4 text-left">
      <div class="mg-y-5 pd-5 pd-b-6 bordered rounded">
        <el-form
          label-position="top"
          :rules="rules"
          ref="ruleForm"
          :model="form"
        >
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item
                style="text-align: initial"
                prop="date"
                class="padding-less custom-form"
              >
                <template slot="label" class="is-flex">
                  <span class="select is-required">
                    กรุณาเลือกวันที่เริ่มต้น - วันที่สิ้นสุด
                  </span>

                  <el-checkbox v-model="form.dateAll" @change="form.date = null"
                    >ทั้งหมด</el-checkbox
                  >
                </template>
                <el-date-picker
                  v-model="form.date"
                  type="daterange"
                  range-separator="ถึง"
                  start-placeholder="วันที่เริ่มต้น"
                  end-placeholder="วันที่สิ้นสุด"
                  class="w-100"
                  :disabled="form.dateAll"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="สถานะการใช้งาน"
                class="padding-less"
                prop="statusDataCompany"
              >
                <el-select
                  multiple
                  filterable
                  default-first-option
                  v-model="form.statusDataCompany"
                  placeholder="เลือกสถานะการใช้งาน"
                  popper-class="custom-select"
                  class="w-100"
                  name="statusDataCompany"
                >
                  <el-option
                    v-for="item in optionsStatusCompany"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="สถานะการประเมิน"
                class="padding-less"
                prop="statusAssessment"
              >
                <el-select
                  multiple
                  filterable
                  default-first-option
                  v-model="form.statusAssessment"
                  placeholder="เลือกสถานะการประเมิน"
                  popper-class="custom-select"
                  class="w-100"
                  name="statusAssessment"
                >
                  <el-option
                    v-for="item in optionsStatusAssessment"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-checkbox v-model="form.dateAll" @change="form.date = null"
                >ทั้งหมด</el-checkbox
              >
            </el-col> -->
          </el-row>
        </el-form>
      </div>
      <el-checkbox
        v-model="userInfo.userInformation"
        @change="handleCheckAllChange"
        >ข้อมูลส่วนตัว</el-checkbox
      >
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.createdAt ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.createdAt"
              >สมัครเมื่อ</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.titleName ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.titleName"
              >คำนำหน้าชื่อ</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.name ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.name"
              >ชื่อ</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.surname ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.surname"
              >นามสกุล</el-checkbox
            ></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.consent ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.consent"
              >ข้อมูลส่วนบุคคล</el-checkbox
            ></el-col
          >

          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.gender ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.gender"
              >เพศ</el-checkbox
            ></el-col
          > -->
          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.age ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.age"
              >อายุ</el-checkbox
            ></el-col
          > -->
          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.education ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.education"
              >วุฒิการศึกษา</el-checkbox
            ></el-col
          > -->
          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.mobilephone
                  ? (userInfo.userInformation = false)
                  : null
              "
              v-model="userInfo.mobilephone"
              >เบอร์โทรศัพท์มือถือ</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.email ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.email"
              >อีเมล</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !userInfo.ref ? (userInfo.userInformation = false) : null
              "
              v-model="userInfo.ref"
              >แหล่งที่มา</el-checkbox
            ></el-col
          >
        </el-row>
      </div>
      <el-divider></el-divider>

      <el-checkbox
        v-model="companyInfo.companyInformation"
        @change="checkAllCompany"
        >ข้อมูลบริษัท</el-checkbox
      >
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.companyName
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.companyName"
              >ชื่อบริษัท</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.juristicId
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.juristicId"
              >เลขนิติบุคคล</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.registerDate
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.registerDate"
              >ปีที่จัดตั้ง</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.registerCapital
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.registerCapital"
              >ทุนจดทะเบียน</el-checkbox
            ></el-col
          >
          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.position
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.position"
              >ตำแหน่ง</el-checkbox
            ></el-col
          > -->
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.tsic
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.tsic"
              >รหัสธุรกิจ 5 หลัก (TSIC)</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.directorType
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.directorType"
              >ประเภทกรรมการ</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.emailCompany
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.emailCompany"
              >อีเมลบริษัท</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.businessType
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.businessType"
              >ลักษณะธุรกิจ</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.cateIndustryType
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.cateIndustryType"
            >
              ประเภทของอุตสาหกรรม</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.industryType
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.industryType"
            >
              ประเภทย่อยของอุตสาหกรรม</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.memberof
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.memberof"
              >หน่วยงานที่เป็นสมาชิก</el-checkbox
            ></el-col
          >
          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.employment
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.employment"
              >การจ้างงาน</el-checkbox
            ></el-col
          > -->
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.latestAnnualRevenues
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.latestAnnualRevenues"
              >รายได้ประจำปีล่าสุดของบริษัท (บาท)</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.operatorType
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.operatorType"
              >ประเภทของผู้ประกอบการ</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.isProduction
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.isProduction"
              >มีการผลิต หรือ ขายให้กับผู้ส่งออก</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.exportPlan
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.exportPlan"
              >แผนที่จะส่งออก</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.moreExportExpectations
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.moreExportExpectations"
              >ร้อยละที่คาดหวังว่าจะส่งออกได้/ส่งออกได้เพิ่มขึ้น</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.exportExperience
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.exportExperience"
              >ประสบการณ์ในการส่งออก</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.exportRatio
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.exportRatio"
              >สัดส่วนในการส่งออก</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.orderConsistency
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.orderConsistency"
              >ความสม่ำเสมอของการสั่งซื้อจาก
              ผู้ซื้อต่างประเทศรายเดิม</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.exportFrequency
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.exportFrequency"
              >ความถี่ในการส่งออกโดยเฉลี่ย</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.shipment
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.shipment"
              >วงเงินค้าขายต่อ Shipment โดยเฉลี่ย</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.paymentTerm
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.paymentTerm"
              >เทอมการชำระเงินโดยเฉลี่ย</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.exportSales
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.exportSales"
              >มูลค่าการส่งออกโดยเฉลี่ยต่อปี</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !companyInfo.exportCountry
                  ? (companyInfo.companyInformation = false)
                  : null
              "
              v-model="companyInfo.exportCountry"
              >จำนวนประเทศที่ทำการส่งออก</el-checkbox
            ></el-col
          >
        </el-row>
      </div>
      <el-divider></el-divider>
      <!-- dbd -->
      <el-checkbox v-model="dbdInfo.dbdInformation" @change="checkAllDBD"
        >ข้อมูล DBD (ย้อนหลัง 5 ปี)</el-checkbox
      >
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <!-- <el-col :span="8">
            <el-checkbox
              @change="!dbdInfo.year ? (dbdInfo.dbdInformation = false) : null"
              v-model="dbdInfo.year"
              >งบกำไรขาดทุน</el-checkbox
            ></el-col
          > -->
          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.totalCurrentAsset
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.totalCurrentAsset"
              >สินทรัพย์หมุนเวียน</el-checkbox
            ></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.accountReceivable
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.accountReceivable"
              >ลูกหนี้การค้า</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.inventory ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.inventory"
              >สินค้าคงคลัง</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.totalAsset ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.totalAsset"
              >สินทรัพย์รวม</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.currentLiabilities
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.currentLiabilities"
              >หนี้สินหมุนเวียน</el-checkbox
            ></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.totalLiabilities
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.totalLiabilities"
              >หนี้สินรวม</el-checkbox
            ></el-col
          >
          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.liabilitiesShareholderEquity
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.liabilitiesShareholderEquity"
              >หนี้สินของผู้ถือหุ้น</el-checkbox
            ></el-col
          > -->
          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.shareholderEquity
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.shareholderEquity"
              >สัดส่วนของผู้ถือหุ้น</el-checkbox
            ></el-col
          >
          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.totalLiabilitiesShareholderEquity
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.totalLiabilitiesShareholderEquity"
              >หนี้สินรวมและสัดส่วนของผู้ถือหุ้น</el-checkbox
            ></el-col
          > -->
          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.totalShare ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.totalShare"
              >แบ่งปันทั้งหมด</el-checkbox
            ></el-col
          > -->
          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.adminExpenses ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.adminExpenses"
              >ค่าใช้จ่ายของผู้ดูแล</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.costOfGoodsSold
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.costOfGoodsSold"
              >ต้นทุนขาย</el-checkbox
            ></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.earningPerShare
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.earningPerShare"
              >รับต่อหุ้น</el-checkbox
            ></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.incomeTax ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.incomeTax"
              >ภาษีเงินได้
            </el-checkbox></el-col
          >

          <!-- <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.interestExpenses
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.interestExpenses"
              >ดอกเบี้ยจ่าย
            </el-checkbox></el-col
          > -->

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.saleRevenue ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.saleRevenue"
              >รายได้จากการขาย
            </el-checkbox></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.totalRevenue ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.totalRevenue"
              >รายได้รวม
            </el-checkbox></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.netProfitBeforeTax
                  ? (dbdInfo.dbdInformation = false)
                  : null
              "
              v-model="dbdInfo.netProfitBeforeTax"
              >กำไร (ขาดทุน) ก่อนภาษี
            </el-checkbox></el-col
          >

          <el-col :span="8">
            <el-checkbox
              @change="
                !dbdInfo.netProfit ? (dbdInfo.dbdInformation = false) : null
              "
              v-model="dbdInfo.netProfit"
              >กำไร(ขาดทุน) สุทธิ
            </el-checkbox></el-col
          >
        </el-row>
      </div>
      <el-divider></el-divider>

      <!-- ประเมิน -->

      <!-- <pre>
          {{ readinessAssessmentFactors }}
      </pre> -->
      <el-checkbox
        v-model="readinessAssessmentFactors.cate"
        @change="checkAllCate"
        >ข้อมูลปัจจัยประเมินความพร้อม</el-checkbox
      >
      <!-- ด้านกลยุทธ์บริหารธุรกิจเพื่อการส่งออก -->
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <el-col :span="24">
            <el-checkbox
              @change="checkAllBMS"
              v-model="readinessAssessmentFactors.businessManagementStrategy"
              >ด้านกลยุทธ์บริหารธุรกิจเพื่อการส่งออก</el-checkbox
            ></el-col
          >

          <div class="mg-l-3">
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.motivation
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.businessManagementStrategy = false)
                    : null
                "
                v-model="readinessAssessmentFactors.motivation"
                >แรงกระตุ้นจากผู้นำ (Motivation)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.businessProcess
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.businessManagementStrategy = false)
                    : null
                "
                v-model="readinessAssessmentFactors.businessProcess"
                >กระบวนการบริหารองค์กร (Business Process)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.strategy
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.businessManagementStrategy = false)
                    : null
                "
                v-model="readinessAssessmentFactors.strategy"
                >แผนกลยุทธ์ (Strategy)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.operatingPlan
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.businessManagementStrategy = false)
                    : null
                "
                v-model="readinessAssessmentFactors.operatingPlan"
                >แผนปฏิบัติการ (Operating Plan)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.strategicPartner
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.businessManagementStrategy = false)
                    : null
                "
                v-model="readinessAssessmentFactors.strategicPartner"
                >พันธมิตรสนับสนุน (Strategic Partner)</el-checkbox
              ></el-col
            >
          </div>
        </el-row>
      </div>

      <!-- ด้านกระบวนการผลิต -->
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <el-col :span="24">
            <el-checkbox
              @change="checkAllPAD"
              v-model="readinessAssessmentFactors.productionAndDistribution"
              >ด้านกระบวนการผลิตและกระจายสินค้า</el-checkbox
            ></el-col
          >

          <div class="mg-l-3">
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.capacity
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productionAndDistribution = false)
                    : null
                "
                v-model="readinessAssessmentFactors.capacity"
                >กำลังการผลิต (Capacity)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.sourcing
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productionAndDistribution = false)
                    : null
                "
                v-model="readinessAssessmentFactors.sourcing"
                >การบริหารวัตถุดิบ (Sourcing)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.facInventory
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productionAndDistribution = false)
                    : null
                "
                v-model="readinessAssessmentFactors.facInventory"
                >การจัดการสินค้าคงคลัง (Inventory)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.logistics
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productionAndDistribution = false)
                    : null
                "
                v-model="readinessAssessmentFactors.logistics"
                >การบริหารการกระจายสินค้า (Logistics)</el-checkbox
              ></el-col
            >
          </div>
        </el-row>
      </div>

      <!-- ด้านลักษณะผลิตภัณฑ์ และการพัฒนาผลิตภัณฑ์ -->
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <el-col :span="24">
            <el-checkbox
              @change="checkAllPAPD"
              v-model="
                readinessAssessmentFactors.productAspectAndProductDevelopment
              "
              >ด้านลักษณะผลิตภัณฑ์ และการพัฒนาผลิตภัณฑ์</el-checkbox
            ></el-col
          >

          <div class="mg-l-3">
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.productDifferentiation
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productAspectAndProductDevelopment = false)
                    : null
                "
                v-model="readinessAssessmentFactors.productDifferentiation"
                >ความโดดเด่นของสินค้า (Product Differentiation)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.barriersToCopy
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productAspectAndProductDevelopment = false)
                    : null
                "
                v-model="readinessAssessmentFactors.barriersToCopy"
                >ความยากในการลอกเลียนแบบสินค้า (Barriers to Copy)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.customization
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productAspectAndProductDevelopment = false)
                    : null
                "
                v-model="readinessAssessmentFactors.customization"
                >การปรับเปลี่ยนสินค้า (Customization)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.standard
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productAspectAndProductDevelopment = false)
                    : null
                "
                v-model="readinessAssessmentFactors.standard"
                >มาตรฐานสินค้า (Standards)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.productDevelopment
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.productAspectAndProductDevelopment = false)
                    : null
                "
                v-model="readinessAssessmentFactors.productDevelopment"
                >การพัฒนาผลิตภัณฑ์ (Product Development)</el-checkbox
              ></el-col
            >
          </div>
        </el-row>
      </div>

      <!-- ด้านแผนการตลาด -->
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <el-col :span="24">
            <el-checkbox
              @change="checkAllMK"
              v-model="readinessAssessmentFactors.marketing"
              >ด้านแผนการตลาด</el-checkbox
            ></el-col
          >

          <div class="mg-l-3">
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.countryMarketExploration
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.marketing = false)
                    : null
                "
                v-model="readinessAssessmentFactors.countryMarketExploration"
                >การสำรวจตลาด (Country Market Exploration)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.prospectExploration
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.marketing = false)
                    : null
                "
                v-model="readinessAssessmentFactors.prospectExploration"
              >
                การสำรวจกลุ่มลูกค้าเป้าหมาย (Prospect Exploration)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.channelExploration
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.marketing = false)
                    : null
                "
                v-model="readinessAssessmentFactors.channelExploration"
                >การสำรวจช่องทางตลาด (Channel Exploration)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.segmentation
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.marketing = false)
                    : null
                "
                v-model="readinessAssessmentFactors.segmentation"
                >การแบ่งกลุ่มลูกค้า (Segmentation)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.positioning
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.marketing = false)
                    : null
                "
                v-model="readinessAssessmentFactors.positioning"
                >ตำแหน่งการตลาด (Positioning)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.branding
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.marketing = false)
                    : null
                "
                v-model="readinessAssessmentFactors.branding"
                >แบรนด์ (Branding)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.pricing
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.marketing = false)
                    : null
                "
                v-model="readinessAssessmentFactors.pricing"
                >กลยุทธ์ด้านราคา (Pricing)</el-checkbox
              ></el-col
            >
          </div>
        </el-row>
      </div>

      <!-- ด้านแผนการเงินและการจัดการความเสี่ยงในธุรกิจ -->
      <div class="mg-t-5 mg-l-3">
        <el-row>
          <el-col :span="24">
            <el-checkbox
              @change="checkAllFPABM"
              v-model="
                readinessAssessmentFactors.financialPlansAndBusinessRiskManagement
              "
              >ด้านแผนการเงินและการจัดการความเสี่ยงในธุรกิจ</el-checkbox
            ></el-col
          >

          <div class="mg-l-3">
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.costStructure
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.financialPlansAndBusinessRiskManagement = false)
                    : null
                "
                v-model="readinessAssessmentFactors.costStructure"
                >โครงสร้างต้นทุน (Cost Structure)</el-checkbox
              ></el-col
            >
            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.financialPlaning
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.financialPlansAndBusinessRiskManagement = false)
                    : null
                "
                v-model="readinessAssessmentFactors.financialPlaning"
                >การวางแผนการเงิน (Financial Planning)</el-checkbox
              ></el-col
            >

            <el-col :span="8">
              <el-checkbox
                @change="
                  !readinessAssessmentFactors.riskMitigation
                    ? (readinessAssessmentFactors.cate = false) &
                      (readinessAssessmentFactors.financialPlansAndBusinessRiskManagement = false)
                    : null
                "
                v-model="readinessAssessmentFactors.riskMitigation"
                >การบริหารความเสี่ยงธุรกิจ (Risk Mitigation)</el-checkbox
              ></el-col
            >
          </div>
        </el-row>
      </div>
      <el-divider></el-divider>
    </div>

    <span slot="footer" class="dialog-footer">
      <div class="text-center">
        <el-button
          type="primary"
          @click="sentExport"
          :disabled="
            (this.form.date == '' && this.form.dateAll == false) ||
            (this.form.date == null && this.form.dateAll == false) ||
            this.form.statusDataCompany.length == 0 ||
            this.form.statusAssessment.length == 0
              ? true
              : false
          "
          >Export CSV</el-button
        >
      </div>
    </span>
  </div>
</template>

<script>
import moment from "moment";
import exportModel from "@/model/export";

export default {
  props: ["columnProp"],
  data() {
    var checkDated = (rule, value, callback) => {
      if (!this.form.dateAll) {
        if (this.form.date == null) {
          return callback(
            new Error("กรุณาเลือกวันที่เริ่มต้น - วันที่สิ้นสุด")
          );
        } else {
          return callback();
        }
      }
    };
    return {
      isIndeterminate: true,
      form: {
        date: "",
        dateAll: false,
        statusDataCompany: [],
        statusAssessment: [],
      },
      optionsStatusCompany: [
        {
          value: 1,
          label: "ใช้งาน",
        },
        {
          value: 0,
          label: "ละเว้น",
        },
      ],
      optionsStatusAssessment: [
        {
          value: 1,
          label: "ประเมินตามค่า Default",
        },
        {
          value: 2,
          label: "อยู่ระหว่างประเมิน",
        },
        {
          value: 3,
          label: "ประเมินเรียบร้อยแล้ว",
        },
      ],
      rules: {
        date: [{ validator: checkDated, trigger: "change" }],
        statusDataCompany: [
          {
            required: true,
            type: "array",
            message: "กรุณาเลือกสถานะการใช้งาน",
            trigger: "change",
          },
        ],
        statusAssessment: [
          {
            required: true,
            type: "array",
            message: "กรุณาเลือกสถานะการประเมิน",
            trigger: "change",
          },
        ],
      },
      finish: true,
      userInfo: {
        userInformation: false,
        createdAt: false,
        titleName: false,
        name: false,
        surname: false,
        consent: false,
        gender: false,
        age: false,
        education: false,
        mobilephone: false,
        email: false,
        ref: false,
      },
      companyInfo: {
        companyInformation: false, //เลือกทั้งหมด
        companyName: false, //ชื่อบริษัท
        juristicId: false, //เลขนิติบุคคล
        registerDate: false, //ปีที่จัดตั้ง
        registerCapital: false, //ทุนจดทะเบียน
        position: false, //ตำแหน่ง
        tsic: false, //รหัสธุรกิจ 5 หลัก (TSIC)
        directorType: false, //ประเภทกรรมการ
        emailCompany: false, //อีเมลบริษัท

        businessType: false, //ลักษณะธุรกิจ
        industryType: false, //ประเภทของอุตสาหกรรม
        cateIndustryType: false, //ประเภทย่อยของอุตสาหกรรม
        memberof: false, //สมาชิกของหน่วยงานใด
        employment: false, //การจ้างงาน
        latestAnnualRevenues: false, // รายได้ประจำปีล่าสุดของบริษัท (บาท)
        operatorType: false, //ประเภทของผู้ประกอบการ
        exportPlan: false, //ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่
        isProduction: false, //ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่
        exportExperience: false, //ประสบการณ์ในการส่งออก
        exportRatio: false, //สัดส่วนในการส่งออก
        orderConsistency: false, //ความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม
        exportFrequency: false, //ความถี่ในการส่งออกโดยเฉลี่ย
        shipment: false,
        paymentTerm: false,
        exportSales: false, //มูลค่าการส่งออก โดยเฉลี่ย
        exportCountry: false, //จำนวนประเทศที่ทำการส่งออก
        moreExportExpectations: false, //ร้อยละที่คาดหวังว่าจะส่งออกได้ส่งออกได้เพิ่มขึ้น
      },
      dbdInfo: {
        dbdInformation: false, //เลือกทั้งหมด
        //year: false, งบกำไรขาดทุน ?
        totalCurrentAsset: false, //สินทรัพย์หมุนเวียน
        currentLiabilities: false, //หนี้สินหมุนเวียน
        accountReceivable: false, //ลูกหนี้
        shareholderEquity: false, //สัดส่วนผู้ถือหุ้น
        inventory: false, //สินค้าคงคลัง
        liabilitiesShareholderEquity: false, //หนี้สินผู้ถือหุ้น
        totalAsset: false, //สินทรัพย์ทั้งหมด
        totalLiabilities: false, //หนี้สินทั้งหมด
        totalLiabilitiesShareholderEquity: false, //หนี้สินรวมส่วนของผู้ถือหุ้น
        totalShare: false, //แบ่งปันทั้งหมด
        adminExpenses: false, //ค่าใช้จ่ายของผู้ดูแล
        costOfGoodsSold: false, //ต้นทุนขาย
        earningPerShare: false, //รับต่อหุ้น
        incomeTax: false, //ภาษีเงินได้
        interestExpenses: false, //ดอกเบี้ยจ่าย
        netProfit: false, //กำไรสุทธิ
        saleRevenue: false, //รายได้จากการขาย
        totalRevenue: false, //รายได้รวม
        netProfitBeforeTax: false, //กำไร (ขาดทุน) ก่อนภาษี
      },
      //ข้อมูลปัจจัยประเมินความพร้อม
      readinessAssessmentFactors: {
        cate: false, //ข้อมูลปัจจัยประเมินความพร้อม(เลือกทั้งหมด)
        businessManagementStrategy: false, //**ด้านกลยุทธ์บริหารธุรกิจเพื่อการส่งออก
        productionAndDistribution: false, //**ด้านกระบวนการผลิตและกระจายสินค้า
        productAspectAndProductDevelopment: false, //**ด้านลักษณะผลิตภัณฑ์ และการพัฒนาผลิตภัณฑ์
        marketing: false, //**ด้านแผนการตลาด
        financialPlansAndBusinessRiskManagement: false, //**ด้านแผนการเงินและการจัดการความเสี่ยงในธุรกิจ

        // ----
        motivation: false, //แรงกระตุ้นจากผู้นำ (Motivation)
        businessProcess: false, //กระบวนการบริหารองค์กร (Business Process)
        strategy: false, //แผนกลยุทธ์ (Strategy)
        operatingPlan: false, //แผนปฏิบัติการ (Operating Plan)
        strategicPartner: false, //พันธมิตรสนับสนุน (Strategic Partner)

        // ----

        capacity: false, //กำลังการผลิต (Capacity)
        sourcing: false, //การบริหารวัตถุดิบ (Sourcing)
        facInventory: false, //การจัดการสินค้าคงคลัง (Inventory)
        logistics: false, //การบริหารการกระจายสินค้า (Logistics)

        // ----
        productDifferentiation: false, //ความโดดเด่นของสินค้า(Product Differentiation)
        barriersToCopy: false, //ความยากในการลอกเลียนแบบสินค้า(Barriers to Copy)
        customization: false, //การปรับเปลี่ยนสินค้า (Customization)
        standard: false, //มาตรฐานสินค้า (Standards)
        productDevelopment: false, //การพัฒนาผลิตภัณฑ์ (Product Development)

        // ----
        countryMarketExploration: false, //การสำรวจตลาด(Country & Market Exploration)
        prospectExploration: false, //การสำรวจกลุ่มลูกค้าเป้าหมาย (Prospect Exploration)
        channelExploration: false, //การสำรวจช่องทางตลาด(Channel Exploration)
        segmentation: false, //การแบ่งกลุ่มลูกค้า (Segmentation)
        positioning: false, //ตำแหน่งการตลาด (Positioning)
        branding: false, //แบรนด์ (Branding)
        pricing: false, //กลยุทธ์ด้านราคา (Pricing)

        // ----

        costStructure: false, //โครงสร้างต้นทุน (Cost Structure)
        financialPlaning: false, //การวางแผนการเงิน (Financial Planning)
        riskMitigation: false, //การบริหารความเสี่ยงธุรกิจ(Risk Mitigation)
      },
    };
  },
  mounted() {
    if (this.columnProp != null) {
      if (this.columnProp.used) {
        this.form.statusDataCompany.push(1);
      }
      if (this.columnProp.notUsed) {
        this.form.statusDataCompany.push(0);
      }

      if (this.columnProp.notAssessment) {
        this.form.statusAssessment.push(1);
      }
      if (this.columnProp.notFinished) {
        this.form.statusAssessment.push(2);
      }
      if (this.columnProp.finish) {
        this.form.statusAssessment.push(3);
      }
      if (this.form.statusDataCompany.length == 0) {
        this.form.statusDataCompany.push(1, 0);
      }
      if (this.form.statusAssessment.length == 0) {
        this.form.statusAssessment.push(1, 2, 3);
      }

      Object.keys(this.userInfo).forEach((item) => {
        this.userInfo[item] = this.columnProp[item];
      });
      Object.keys(this.companyInfo).forEach((item) => {
        this.companyInfo[item] = this.columnProp[item];
      });
      Object.keys(this.dbdInfo).forEach((item) => {
        this.dbdInfo[item] = this.columnProp[item];
      });
      Object.keys(this.readinessAssessmentFactors).forEach((item) => {
        this.readinessAssessmentFactors[item] = this.columnProp[item];
      });
    }
  },
  methods: {
    sentExport() {
      let customfield = exportModel;

      let company0 = this.form.statusDataCompany.filter(
        (filter) => filter == 0
      );
      let company1 = this.form.statusDataCompany.filter(
        (filter) => filter == 1
      );

      let assessment1 = this.form.statusAssessment.filter(
        (filter) => filter == 1
      );
      let assessment2 = this.form.statusAssessment.filter(
        (filter) => filter == 2
      );
      let assessment3 = this.form.statusAssessment.filter(
        (filter) => filter == 3
      );
      if (this.form.dateAll) {
        customfield.start = "";
        customfield.end = "";
      } else {
        customfield.start = moment(this.form.date[0]).format("yyyy-MM-DD");
        customfield.end = moment(this.form.date[1]).format("yyyy-MM-DD");
      }
      if (company0.length == 1) {
        customfield.notUsed = true;
      } else {
        customfield.notUsed = false;
      }
      if (company1.length == 1) {
        customfield.used = true;
      } else {
        customfield.used = false;
      }
      if (assessment1.length == 1) {
        customfield.notAssessment = true;
      } else {
        customfield.notAssessment = false;
      }
      if (assessment2.length == 1) {
        customfield.notFinished = true;
      } else {
        customfield.notFinished = false;
      }
      if (assessment3.length == 1) {
        customfield.finish = true;
      } else {
        customfield.finish = false;
      }

      Object.keys(this.userInfo).forEach((item) => {
        if (
          item == "gender" ||
          item == "age" ||
          item == "education"
          // || item == "titleName"
        ) {
          customfield[item] = false;
        } else {
          customfield[item] = this.userInfo[item];
        }
      });
      Object.keys(this.companyInfo).forEach((item) => {
        if (item == "position" || item == "employment") {
          customfield[item] = false;
        } else {
          customfield[item] = this.companyInfo[item];
        }
      });
      Object.keys(this.dbdInfo).forEach((item) => {
        if (
          item == "year" ||
          item == "liabilitiesShareholderEquity" ||
          item == "totalShare" ||
          item == "interestExpenses"
        ) {
          customfield[item] = false;
        } else {
          customfield[item] = this.dbdInfo[item];
        }
      });
      Object.keys(this.readinessAssessmentFactors).forEach((item) => {
        customfield[item] = this.readinessAssessmentFactors[item];
      });
      this.$emit("sentExport", customfield);
    },
    handleCheckAllChange() {
      if (this.userInfo.userInformation == true) {
        Object.keys(this.userInfo).forEach((item) => {
          this.userInfo[item] = true;
        });
      } else {
        Object.keys(this.userInfo).forEach((item) => {
          this.userInfo[item] = false;
        });
      }
    },

    checkAllCompany() {
      if (this.companyInfo.companyInformation == true) {
        Object.keys(this.companyInfo).forEach((item) => {
          this.companyInfo[item] = true;
        });
      } else {
        Object.keys(this.companyInfo).forEach((item) => {
          this.companyInfo[item] = false;
        });
      }
    },
    checkAllDBD() {
      if (this.dbdInfo.dbdInformation == true) {
        Object.keys(this.dbdInfo).forEach((item) => {
          this.dbdInfo[item] = true;
        });
      } else {
        Object.keys(this.dbdInfo).forEach((item) => {
          this.dbdInfo[item] = false;
        });
      }
    },

    checkAllCate() {
      if (this.readinessAssessmentFactors.cate == true) {
        this.readinessAssessmentFactors.businessManagementStrategy = true;
        this.readinessAssessmentFactors.productionAndDistribution = true;
        this.readinessAssessmentFactors.productAspectAndProductDevelopment = true;
        this.readinessAssessmentFactors.marketing = true;
        this.readinessAssessmentFactors.financialPlansAndBusinessRiskManagement = true;

        this.readinessAssessmentFactors.motivation = true;
        this.readinessAssessmentFactors.businessProcess = true;
        this.readinessAssessmentFactors.strategy = true;
        this.readinessAssessmentFactors.operatingPlan = true;
        this.readinessAssessmentFactors.strategicPartner = true;

        this.readinessAssessmentFactors.capacity = true;
        this.readinessAssessmentFactors.sourcing = true;
        this.readinessAssessmentFactors.facInventory = true;
        this.readinessAssessmentFactors.logistics = true;

        this.readinessAssessmentFactors.productDifferentiation = true;
        this.readinessAssessmentFactors.barriersToCopy = true;
        this.readinessAssessmentFactors.customization = true;
        this.readinessAssessmentFactors.standard = true;
        this.readinessAssessmentFactors.productDevelopment = true;

        this.readinessAssessmentFactors.countryMarketExploration = true;
        this.readinessAssessmentFactors.prospectExploration = true;
        this.readinessAssessmentFactors.channelExploration = true;
        this.readinessAssessmentFactors.segmentation = true;
        this.readinessAssessmentFactors.positioning = true;
        this.readinessAssessmentFactors.branding = true;
        this.readinessAssessmentFactors.pricing = true;

        this.readinessAssessmentFactors.costStructure = true;
        this.readinessAssessmentFactors.financialPlaning = true;
        this.readinessAssessmentFactors.riskMitigation = true;
      } else {
        this.readinessAssessmentFactors.businessManagementStrategy = false;
        this.readinessAssessmentFactors.productionAndDistribution = false;
        this.readinessAssessmentFactors.productAspectAndProductDevelopment = false;
        this.readinessAssessmentFactors.marketing = false;
        this.readinessAssessmentFactors.financialPlansAndBusinessRiskManagement = false;

        this.readinessAssessmentFactors.motivation = false;
        this.readinessAssessmentFactors.businessProcess = false;
        this.readinessAssessmentFactors.strategy = false;
        this.readinessAssessmentFactors.operatingPlan = false;
        this.readinessAssessmentFactors.strategicPartner = false;

        this.readinessAssessmentFactors.capacity = false;
        this.readinessAssessmentFactors.sourcing = false;
        this.readinessAssessmentFactors.facInventory = false;
        this.readinessAssessmentFactors.logistics = false;

        this.readinessAssessmentFactors.productDifferentiation = false;
        this.readinessAssessmentFactors.barriersToCopy = false;
        this.readinessAssessmentFactors.customization = false;
        this.readinessAssessmentFactors.standard = false;
        this.readinessAssessmentFactors.productDevelopment = false;

        this.readinessAssessmentFactors.countryMarketExploration = false;
        this.readinessAssessmentFactors.prospectExploration = false;
        this.readinessAssessmentFactors.channelExploration = false;
        this.readinessAssessmentFactors.segmentation = false;
        this.readinessAssessmentFactors.positioning = false;
        this.readinessAssessmentFactors.branding = false;
        this.readinessAssessmentFactors.pricing = false;

        this.readinessAssessmentFactors.costStructure = false;
        this.readinessAssessmentFactors.financialPlaning = false;
        this.readinessAssessmentFactors.riskMitigation = false;
      }
    },

    checkAllBMS() {
      if (this.readinessAssessmentFactors.businessManagementStrategy == true) {
        this.readinessAssessmentFactors.motivation = true;
        this.readinessAssessmentFactors.businessProcess = true;
        this.readinessAssessmentFactors.strategy = true;
        this.readinessAssessmentFactors.operatingPlan = true;
        this.readinessAssessmentFactors.strategicPartner = true;
      } else {
        this.readinessAssessmentFactors.cate = false;
        this.readinessAssessmentFactors.motivation = false;
        this.readinessAssessmentFactors.businessProcess = false;
        this.readinessAssessmentFactors.strategy = false;
        this.readinessAssessmentFactors.operatingPlan = false;
        this.readinessAssessmentFactors.strategicPartner = false;
      }
    },

    checkAllPAD() {
      if (this.readinessAssessmentFactors.productionAndDistribution == true) {
        this.readinessAssessmentFactors.capacity = true;
        this.readinessAssessmentFactors.sourcing = true;
        this.readinessAssessmentFactors.facInventory = true;
        this.readinessAssessmentFactors.logistics = true;
      } else {
        this.readinessAssessmentFactors.cate = false;
        this.readinessAssessmentFactors.capacity = false;
        this.readinessAssessmentFactors.sourcing = false;
        this.readinessAssessmentFactors.facInventory = false;
        this.readinessAssessmentFactors.logistics = false;
      }
    },

    checkAllPAPD() {
      if (this.readinessAssessmentFactors.productAspectAndProductDevelopment) {
        this.readinessAssessmentFactors.productDifferentiation = true;
        this.readinessAssessmentFactors.barriersToCopy = true;
        this.readinessAssessmentFactors.customization = true;
        this.readinessAssessmentFactors.standard = true;
        this.readinessAssessmentFactors.productDevelopment = true;
      } else {
        this.readinessAssessmentFactors.cate = false;
        this.readinessAssessmentFactors.productDifferentiation = false;
        this.readinessAssessmentFactors.barriersToCopy = false;
        this.readinessAssessmentFactors.customization = false;
        this.readinessAssessmentFactors.standard = false;
        this.readinessAssessmentFactors.productDevelopment = false;
      }
    },

    checkAllMK() {
      if (this.readinessAssessmentFactors.marketing == true) {
        this.readinessAssessmentFactors.countryMarketExploration = true;
        this.readinessAssessmentFactors.prospectExploration = true;
        this.readinessAssessmentFactors.channelExploration = true;
        this.readinessAssessmentFactors.segmentation = true;
        this.readinessAssessmentFactors.positioning = true;
        this.readinessAssessmentFactors.branding = true;
        this.readinessAssessmentFactors.pricing = true;
      } else {
        this.readinessAssessmentFactors.cate = false;
        this.readinessAssessmentFactors.countryMarketExploration = false;
        this.readinessAssessmentFactors.prospectExploration = false;
        this.readinessAssessmentFactors.channelExploration = false;
        this.readinessAssessmentFactors.segmentation = false;
        this.readinessAssessmentFactors.positioning = false;
        this.readinessAssessmentFactors.branding = false;
        this.readinessAssessmentFactors.pricing = false;
      }
    },

    checkAllFPABM() {
      if (
        this.readinessAssessmentFactors
          .financialPlansAndBusinessRiskManagement == true
      ) {
        this.readinessAssessmentFactors.costStructure = true;
        this.readinessAssessmentFactors.financialPlaning = true;
        this.readinessAssessmentFactors.riskMitigation = true;
      } else {
        this.readinessAssessmentFactors.cate = false;
        this.readinessAssessmentFactors.costStructure = false;
        this.readinessAssessmentFactors.financialPlaning = false;
        this.readinessAssessmentFactors.riskMitigation = false;
      }
    },
  },
};
</script>

<style scoped>
.el-checkbox {
  margin-bottom: 10px;
}
</style>
